<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <admin-nav />
      <div class="col-lg-10 col-sm-12">
        <div class="container">
          <h4 class="mb-">Manage "{{ course.name || 'new' }}" course</h4>
          <form @submit.prevent="saveCourse()">
            <div class="form-group mb-3">
              <label for="name">Course name</label>
              <input class="form-control" type="text" name="name" id="name" v-model="course.name" required>
            </div>
            <div class="form-group mb-3">
              <label for="description">Description</label>
              <textarea class="form-control" name="description" id="description" cols="30" rows="10" v-model="course.description" required></textarea>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6 mb-3">
                <h4>Logo</h4>
                <label for="image">Image file</label>
                <input ref="fileInput" @change="setImage()" class="form-control mb-3" type="file" name="image" id="image">
                <div><button @click="removeImage()" type="button" class="btn btn-danger mb-3">Remove image</button></div>
                <img v-if="imageUrl" width="400" class="img-thumbnail mw-100" :src="imageUrl" alt="Course image">
              </div>
              <div class="col-sm-12 col-md-6 mb-3">
                <h4>Trial</h4>
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" name="hasDiscountedTrial" id="hasDiscountedTrial" v-model="course.hasDiscountedTrial">
                  <label class="form-check-label" for="hasDiscountedTrial">Enable trial?</label>
                </div>
                <div class="row" v-show="course.hasDiscountedTrial">
                  <div class="form-group mb-3 col-6 col-md-12 col-lg-6">
                    <label for="trialClassCount">Number of trial classes</label>
                    <input class="form-control" type="number" name="trialClassCount" id="trialClassCount" v-model.number="course.trialClassCount" min="0" step="1" required>
                  </div>
                  <div class="form-group mb-3 col-6 col-md-12 col-lg-6">
                    <label for="trialDiscountPercentage">Discount percentage</label>
                    <div class="input-group">
                      <span class="input-group-text">%</span>
                      <input class="form-control" type="number" name="trialDiscountPercentage" id="trialDiscountPercentage" v-model.number="course.trialDiscountPercentage" min="0" step="1" max="100" required>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
              <button class="btn btn-primary" type="submit" :disabled="waiting">Save course</button>
              <button class="btn btn-danger" type="button" @click="deleteCourse()">Delete course</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Course from '@/types/course'
import AdminNav from '@/components/AdminNav.vue'
import getCourseImageUrl from '@/utils/getCourseImageUrlMixin'

const emptyCourse: Course = {
  id: 0,
  name: '',
  description: '',
  hasDiscountedTrial: false,
  trialClassCount: 0,
  trialDiscountPercentage: 0
}

export default defineComponent({
  components: { AdminNav },
  mixins: [getCourseImageUrl],
  data () {
    return {
      waiting: false,
      course: emptyCourse,
      image: ''
    }
  },
  async mounted () {
    const courseId = parseInt(this.$route.params.id as string)
    if (!isNaN(courseId)) {
      const response = await this.$api.getCourse(courseId)
      this.course = response.course || emptyCourse
    }
  },
  methods: {
    async saveCourse () {
      this.waiting = true
      let response
      const payload = { ...this.course }
      if (this.image.length > 0) {
        payload.image = this.image
      }
      if (payload.id) {
        response = await this.$api.updateCourse(payload)
      } else {
        response = await this.$api.createCourse(payload)
      }
      if (response.course) {
        this.waiting = false
        if (!this.course.id) {
          return this.$router.replace({ name: 'admin_course', params: { id: response.course.id } })
        }
        this.course = response.course
      }
    },
    async deleteCourse () {
      if (!confirm('Are you sure you want to delete this course?')) {
        return
      }
      let success = true
      if (this.course.id) {
        const response = await this.$api.deleteCourse(this.course.id)
        if (!response.success) {
          success = false
        }
      }
      if (success) {
        return this.$router.replace({ name: 'admin_course_list' })
      }
    },
    removeImage () {
      if (!confirm('Are you sure you want to delete this image?')) {
        return
      }
      this.image = 'delete'
      const fileInput = this.$refs.fileInput as HTMLInputElement
      fileInput.value = ''
    },
    setImage () {
      const fileInput = this.$refs.fileInput as HTMLInputElement
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          if (typeof reader.result === 'string') {
            this.image = reader.result
          }
        }, false)
        reader.readAsDataURL(file)
      }
    }
  },
  computed: {
    imageUrl (): string {
      if (this.image === 'delete') {
        return ''
      }
      if (this.course.id && !this.image) {
        return this.getCourseImageUrl(this.course.id)
      }
      return this.image
    }
  }
})
</script>
