
import { defineComponent } from 'vue'
import Course from '@/types/course'
import AdminNav from '@/components/AdminNav.vue'
import getCourseImageUrl from '@/utils/getCourseImageUrlMixin'

const emptyCourse: Course = {
  id: 0,
  name: '',
  description: '',
  hasDiscountedTrial: false,
  trialClassCount: 0,
  trialDiscountPercentage: 0
}

export default defineComponent({
  components: { AdminNav },
  mixins: [getCourseImageUrl],
  data () {
    return {
      waiting: false,
      course: emptyCourse,
      image: ''
    }
  },
  async mounted () {
    const courseId = parseInt(this.$route.params.id as string)
    if (!isNaN(courseId)) {
      const response = await this.$api.getCourse(courseId)
      this.course = response.course || emptyCourse
    }
  },
  methods: {
    async saveCourse () {
      this.waiting = true
      let response
      const payload = { ...this.course }
      if (this.image.length > 0) {
        payload.image = this.image
      }
      if (payload.id) {
        response = await this.$api.updateCourse(payload)
      } else {
        response = await this.$api.createCourse(payload)
      }
      if (response.course) {
        this.waiting = false
        if (!this.course.id) {
          return this.$router.replace({ name: 'admin_course', params: { id: response.course.id } })
        }
        this.course = response.course
      }
    },
    async deleteCourse () {
      if (!confirm('Are you sure you want to delete this course?')) {
        return
      }
      let success = true
      if (this.course.id) {
        const response = await this.$api.deleteCourse(this.course.id)
        if (!response.success) {
          success = false
        }
      }
      if (success) {
        return this.$router.replace({ name: 'admin_course_list' })
      }
    },
    removeImage () {
      if (!confirm('Are you sure you want to delete this image?')) {
        return
      }
      this.image = 'delete'
      const fileInput = this.$refs.fileInput as HTMLInputElement
      fileInput.value = ''
    },
    setImage () {
      const fileInput = this.$refs.fileInput as HTMLInputElement
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          if (typeof reader.result === 'string') {
            this.image = reader.result
          }
        }, false)
        reader.readAsDataURL(file)
      }
    }
  },
  computed: {
    imageUrl (): string {
      if (this.image === 'delete') {
        return ''
      }
      if (this.course.id && !this.image) {
        return this.getCourseImageUrl(this.course.id)
      }
      return this.image
    }
  }
})
